import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static values = { limit: Number }

  connect() {
    $(this.element).checkboxlimiter({
      limitcheckbox: this.limitValue
    });
  }
}
